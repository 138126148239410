@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiInputBase-root {
  background-color: white;
}

.react-tel-input .form-control {
  width: 100%;
}
body {
  -webkit-overflow-scrolling: touch;
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin: 0;
    font-weight: bold;
  }
  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em !important;
  }
  h4 {
    font-size: 1em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }
}

@keyframes slide {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(0px);
  }
  85% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fade {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@keyframes popUp {
  0% {
    transform: translateY(97%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes popDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(160%);
  }
}

@keyframes popRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeExit {
  0% {
    background-color: rgba(0, 0, 0, 0.4);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

.custom-phone-dropdown {
  max-height: 200px !important; /* Limita el tamaño y agrega scroll */
  overflow-y: auto !important;
  position: absolute !important;
}

.custom-phone-container {
  position: relative;
}

.custom-phone-button {
  position: relative;
  z-index: 10;
}

/* Etiqueta flotante de los campos de teléfono */
.react-tel-input .special-label {
  display: block;
  top: -10px;
  color: #4b5563;
}

/* Opciones del select de teléfono */
.react-tel-input .country-list .country {
  padding: 7px 5px 7px 36px;
}

/* Clases para las animaciones */
.animate-slide {
  /* will-change: transform; */
  animation: slide 1s ease-in-out forwards;
}

.animate-fade {
  /* will-change: transform; */
  animation: fade 0.34s ease-in-out forwards;
}

.animate-popUp {
  /* will-change: transform; */
  animation: popUp 0.34s ease-in-out forwards;
}

.animate-popLeft {
  /* will-change: transform; */
  animation: popLeft 0.34s ease-out forwards;
}

.animate-popDown {
  /* will-change: transform; */
  animation: popDown 0.34s ease-in-out forwards;
}

.animate-popRight {
  /* will-change: transform; */
  animation: popRight 0.34s ease-in;
}

.animate-fadeExit {
  /* will-change: transform; */
  animation: fadeExit 0.34s ease-in-out forwards;
}

.animate-fadeExit2 {
  /* will-change: transform; */
  animation: fade;
  animation: fadeExit 0.34s ease-in-out forwards;
}

.MuiInputBase-root {
  height: 48px;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 5px !important;
}

[data-tooltip]:hover::after {
  z-index: 99;
  display: block;
  position: absolute;
  content: attr(data-tooltip);
  background: color(srgb 0.07 0.09 0.15 / 0.9);
  color: white;
  font-size: x-small;
  white-space: nowrap;
  border-radius: 5px;
  right: 0px;
  padding: 0.5em;
  height: fit-content;
  max-width: 200px;
  text-wrap: initial;
}

textarea.MuiInputBase-input {
  height: 1.1876em !important;
}
